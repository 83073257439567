import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/templates/Docs/index.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "error-handling",
      "style": {
        "position": "relative"
      }
    }}>{`Error Handling`}<a parentName="h1" {...{
        "href": "#error-handling",
        "aria-label": "error handling permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h1>
    <p>{`Errors can be outputted at all steps of the application process. It is
quite possible that errors will be returned whilst the application is
still capable of being run, i.e. incorrect login details. These error
codes are used to keep you apprised of any issues with the application
process.`}</p>
    <p>{`Bolded errors are the most basic error response. These are rarely used,
if possible we will provide an error code under it identifying the issue
more explicitly, if available.`}</p>
    <h2 {...{
      "id": "error-codes",
      "style": {
        "position": "relative"
      }
    }}>{`Error Codes`}<a parentName="h2" {...{
        "href": "#error-codes",
        "aria-label": "error codes permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Error Code`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Code Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description Breakdown`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`1000`}</strong></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><strong parentName="td">{`Bank login error.`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1001`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Incorrect login details have been provided by the user.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`This error usually appears in tandem with an auth request allowing the user to retry their login details.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1002`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Exceeded login attempts.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`In this case the user has incorrectly inputted their login details too many times and the bank has potentially locked us out of the account.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1003`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Locked bank account.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`This means that the bank has locked us out of the account. This could be for a number of different reasons, from too many incorrect login attempts, or running out of time in inputting a verification code`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1004`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`SMS code suspended.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`This means that the SMS code that the user provided has expired and is not usable by us to access the account.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1005`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`SMS Exceeded Retry Attempts.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`In this case, too many SMS code attempts have been tried to access the account, all of which were incorrect. This usually comes with a locked bank account (1003) notice straight after`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1006`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Account has been suspended.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`In this case, the credentials we've been provided have been to a bank account that has been suspended by the banking institution hosting it. This can be for any number of reasons, from potential fraud to the user just shutting down the account previously.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1007`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Additional inputs required.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`This is a warning notifying you that a third input is required to authenticate the user. This could be because of MFA (multifactor authentication). Some banks, such as Bank of Melbourne, require this to be the default.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1008`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Requesting users initial credential inputs.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`This message is notifying you that the username and password have not been submitted yet to log in to this account.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1009`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`User exit.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`User has left the system before all transactions were successfully completed.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1010`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Unable to find mobile bank`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unable to use mobile bank login. Please use website login credentials.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`2000`}</strong></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><strong parentName="td">{`Bank requires user input.`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2001`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Need to login and update personal details.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Usually occurring on new accounts, this a pop-up that can occur on some banks requiring that the user login and provide their personal details for the bank.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2002`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Login and agree to terms`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Usually this is the bank updating its terms and conditions and requiring that the user accept before they can access their account. As such, we cannot access the system until this is completed by the user.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2003`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`You need to set a permanent password with the bank.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`This notice appears when the account has only recently been made by the user. Some banks will provide a temporary password that must be changed to a permanent one on the first login. As we cannot set a user's password they must log in and do this themselves before our system can continue.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2004`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Could not process MFA.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`This means that the response sent by the user in return to an MFA request (1007) was incorrect.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2005`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Verify Account.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Similar to 2003, this usually occurs on new accounts that haven't been verified by the user in the first login.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2006`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Password change required`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Usually occurs with banks that have a regular password change policy.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`3000`}</strong></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><strong parentName="td">{`Timed Out.`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3001`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Network timed out - try again in a minute.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Connection to the bank failed due to network failure.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3002`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`User input time out - session has been ended.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`This notice is sent when the session has timed out for any other reason except for waiting on inputs.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3003`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`User input time out - user took too long to input credentials.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`This error is sent after the session has expired whilst waiting for the user to input their username and password.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3004`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Session timed out - user took too long to input MFA.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`This error is sent after the session has expired whilst waiting for the user to input their multifactor authentication request (e.g. SMS, dob, secret question)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3005`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Session has logged out during crawl`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`This is sent when the bank logs us out of the system. This usually occurs due to the user logging in themselves.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3006`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Timed out before AUTH`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A specific error showing when the time out for the bank crawl occurred. In this case before AUTH lifecycle.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3007`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Timed out during AUTH`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A specific error showing when the time out for the bank crawl occurred. In this case during AUTH lifecycle.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3008`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Timed out after RELEASED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A specific error showing when the time out for the bank crawl occurred. In this case after the RELEASED lifecycle`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`4000`}</strong></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><strong parentName="td">{`Problem retrieving information.`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`4001`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`The bank accounts have been closed.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`This notice is sent when the bank account that is being processed is listed as closed. Closed accounts can sometimes not be accessed for data.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`4002`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Bank system is being updated and cannot be accessed.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Sent when the bank is undergoing a system update. These happen semi-frequently for each bank, but are during off hours late at night so it is unlikely that you will receive this message often.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`4003`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`The request cannot be completed as a subsequent login was attempted.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Similar to 3005, this notice is sent when it is known that the reason we were logged out was due to another login attempt.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`4004`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Supplied bank credentials have expired.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Sent when the username/password combination is an old one.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`4005`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Online banking setup has not yet been finalized.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`This notice is sent when the initial banking setup for the account has not yet been completed. This is usually an indication that the account is very new.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`4006`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`You are not registered for this service.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`This notice is sent when the user no longer has an account with the bank we are trying access, or if they have deliberately removed access to the online banking component of their service. If this returned with Centrelink, it is possibly due to them never having had a Centrelink account set up.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`4007`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Found no data in all of the accounts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Found no data in all of the accounts. That is, the total number of transactions is 0. The balance of all accounts is 0. Sometimes seen in disabled, hidden, or new accounts.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`4008`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Bank is currently unable to provide transactions`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An issue with the bank has prevented collection of transactions.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`4009`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Bank is currently unable to provide statements`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An issue with the bank has prevented collection of statements.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`4010`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Bank is currently unable to provide interims`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An issue with the bank has prevented collection of interims.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`4011`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`User bank account does not contain any accounts. Unable to collect accounts or transactions`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The account has no accounts to collect data from.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`4012`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`User bank account does not contain any data within the specified date range.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`There may be transactions in the account however not within the specified days to retrieve.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`4013`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Bank is currently unable to provide account balances.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An issue with the bank has prevented collection of account balances.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`4014`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Please login and change your document delivery option to digital one.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The document delivery option should be set to the digital one.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`4015`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Your vehicle registration needs to be renewed.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The vehicle registration needs to be renewed.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`4016`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`This acccount have already been registered under other names.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`This account is already associated with different names.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`4017`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`This MyGov account has not linked any ATO/CentreLink/Medicare service yet.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`MyGov accounts should be linked to ATO/CentreLink/Medicare to get access to these services.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`4018`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Mygov service is offline now. Please check their website for more information.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Mygov service is currently unavailable.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`4019`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`ATO site is having technical difficulties and cannot provide transaction details.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ATO service is currently unavailable.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`4020`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Centrelink service is offline due to maintenance.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Centrelink service is under maintenance.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`4021`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Centrelink service is not linked to your myGov account.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Centrelink service should be linked to myGov account before processing.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`4022`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`ATO service is not linked to your myGov account.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ATO service should be linked to myGov account before processing.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`4023`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Need to login and activate your two-step verification.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`This institution requires you to activate the two-step verification before login. Please log into your account's website and set it up before reapplying through Talefin.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`5000`}</strong></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><strong parentName="td">{`Network issues.`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`5001`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Bank service is currently unavailable.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Sent when the system is down on the banks end outside of their system update schedule.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`5002`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Bankmodule not available, coming soon.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Sent when the bank that is being requested is not currently supported within the system.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`5003`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Bank is currently unable to provide transaction history at this time`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Usually when an issue with the bank system prevents transaction data from being retrieved.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`5004`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Logged out during crawl and unable to relogin`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An error occurred during the crawl and we were unable to continue, usually due to MFA.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`6000`}</strong></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><strong parentName="td">{`Unable to retrieve statements.`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`7000`}</strong></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><strong parentName="td">{`Try again tomorrow.`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`7001`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Something went wrong. Try again.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Sent when another login is required on a refresh, or when an error occurs that would be resolved upon recrawling.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`8000`}</strong></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><strong parentName="td">{`Cannot assist right now.`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`8001`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Unknown error.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Generic error. Sent when something very unusual has happened that hasn't been pinpointed. This is flagged in our system for us to investigate.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`8002`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`An error occurred when processing this account.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Generic error. Sent when something very unusual has happened that hasn't been pinpointed. This is flagged in our system for us to investigate.`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "example-payload",
      "style": {
        "position": "relative"
      }
    }}>{`Example Payload`}<a parentName="h2" {...{
        "href": "#example-payload",
        "aria-label": "example payload permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <deckgo-highlight-code {...{
      "language": "json"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`{
  "event": "crawler.lifecycle_updated",
  "payload": {
    "uuid": "b18fd94f-a1b8-4572-965d-3ba16a70c7ed",
    "vendor_specific_id": "200130a",
    "bank_id": 130,
    "timestamp": "2020-01-30T00:30:23.724232Z",
    "status": "AUTH",
    "code": 1001,
    "message": "Incorrect Login details"
  }
}`}</code>{`
        `}</deckgo-highlight-code>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      